import { defineComponent, ref } from 'vue';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  name: 'Event-Content',
  components: {
    SvgIcon: SvgIcon
  },
  inject: ['isMobile'],
  setup: function setup() {
    var copyEle = ref();

    var copy = function copy() {
      try {
        navigator.clipboard.writeText('vipcar');
        copyEle.value.classList.add('active');
        setTimeout(function () {
          copyEle.value.classList.remove('active');
        }, 2001);
      } catch (error) {
        console.log('err', error);
      }
    };

    return {
      copy: copy,
      copyEle: copyEle
    };
  }
});