import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-14e0c745"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  key: 1
};
var _hoisted_2 = ["src"];
var _hoisted_3 = {
  class: "event-pc-search"
};
var _hoisted_4 = {
  class: "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_header_component = _resolveComponent("header-component");

  var _component_MobileSearch = _resolveComponent("MobileSearch");

  var _component_PcSearch = _resolveComponent("PcSearch");

  var _component_event_content_fubon = _resolveComponent("event-content-fubon");

  var _component_event_content_tsb = _resolveComponent("event-content-tsb");

  var _component_event_content_vipcar = _resolveComponent("event-content-vipcar");

  var _component_hito_section = _resolveComponent("hito-section");

  var _component_event_notice_tsb = _resolveComponent("event-notice-tsb");

  var _component_event_notice_vipcar = _resolveComponent("event-notice-vipcar");

  var _component_event_notice_fubon = _resolveComponent("event-notice-fubon");

  var _component_footer_component = _resolveComponent("footer-component");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_header_component), _ctx.isMobile ? (_openBlock(), _createBlock(_component_MobileSearch, {
    key: 0,
    home: "",
    className: _ctx.mobileClass,
    bannerImg: _ctx.banner,
    initExpandStatus: true
  }, null, 8, ["className", "bannerImg"])) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("img", {
    src: _ctx.banner,
    class: "event-img"
  }, null, 8, _hoisted_2), _createElementVNode("div", _hoisted_3, [_createVNode(_component_PcSearch)])])), _createElementVNode("div", {
    class: "bg",
    style: _normalizeStyle({
      '--bgImg': _ctx.bgImg ? "url(".concat(_ctx.bgImg, ")") : undefined
    })
  }, [_createElementVNode("div", _hoisted_4, [_ctx.eventType === 'fubon' ? (_openBlock(), _createBlock(_component_event_content_fubon, {
    key: 0
  })) : _ctx.eventType === 'TSB' ? (_openBlock(), _createBlock(_component_event_content_tsb, {
    key: 1
  })) : _ctx.eventType === 'vipcar' ? (_openBlock(), _createBlock(_component_event_content_vipcar, {
    key: 2
  })) : _createCommentVNode("", true), _createVNode(_component_hito_section, {
    eventTitle: _ctx.title,
    className: _ctx.titleClass
  }, null, 8, ["eventTitle", "className"])])], 4), _ctx.eventType === 'TSB' ? (_openBlock(), _createBlock(_component_event_notice_tsb, {
    key: 2
  })) : _ctx.eventType === 'vipcar' ? (_openBlock(), _createBlock(_component_event_notice_vipcar, {
    key: 3
  })) : _ctx.eventType === 'fubon' ? (_openBlock(), _createBlock(_component_event_notice_fubon, {
    key: 4
  })) : _createCommentVNode("", true), _createVNode(_component_footer_component)], 64);
}