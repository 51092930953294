import { defineComponent, computed, onMounted, inject } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import MobileSearch from '@/components/mobile/MobileSearch.vue';
import PcSearch from '@/components/hotels/PcSearch.vue';
import Header from '@/components/Header.vue';
import HitoSection from '@/components/home/HitoSection.vue';
import Footer from '@/components/Footer.vue';
import EventContentVipcar from '@/components/event/VIPCAR/Content.vue';
import EventContentTsb from '@/components/event/TSB/Content.vue';
import EventContentFubon from '@/components/event/fubon/Content.vue';
import EventNoticeTsb from '@/components/event/TSB/Notice.vue';
import EventNoticeVipcar from '@/components/event/VIPCAR/Notice.vue';
import EventNoticeFubon from '@/components/event/fubon/Notice.vue';
export default defineComponent({
  name: 'Home',
  components: {
    HeaderComponent: Header,
    HitoSection: HitoSection,
    FooterComponent: Footer,
    EventContentVipcar: EventContentVipcar,
    EventContentTsb: EventContentTsb,
    EventContentFubon: EventContentFubon,
    EventNoticeTsb: EventNoticeTsb,
    EventNoticeVipcar: EventNoticeVipcar,
    EventNoticeFubon: EventNoticeFubon,
    PcSearch: PcSearch,
    MobileSearch: MobileSearch
  },
  setup: function setup() {
    var isMobile = inject('isMobile');
    var locale = inject('locale');
    var router = useRouter();
    var store = useStore();
    var path = computed(function () {
      return router.currentRoute.value;
    });

    var getImgUrl = function getImgUrl(event) {
      var images = require.context('../assets/images/', false, /\.jpg$/);

      return images('./' + event + '.jpg');
    };

    var getEventType = function getEventType() {
      switch (path.value.path) {
        case "/".concat(locale.value, "/event/TSB"):
        case "/".concat(locale.value, "/event/TSBTRAVEL"):
          return {
            type: 'TSB',
            guestNumber: 2,
            bannerImg: isMobile.value ? getImgUrl('TSIB-Banner-m') : getImgUrl('TSIB-Banner'),
            mobileClass: 'home-mobile-search event',
            title: 'tourbobo 熱門人氣住宿'
          };

        case "/".concat(locale.value, "/event/vipcar"):
          return {
            type: 'vipcar',
            guestNumber: 1,
            bannerImg: isMobile.value ? getImgUrl('VIPCAR-Banner-m') : getImgUrl('VIPCAR-Banner'),
            bgImg: getImgUrl('carvip-bg-1'),
            mobileClass: 'home-mobile-search vipcar',
            title: '熱門人氣住宿'
          };

        case "/".concat(locale.value, "/event/fubon"):
          return {
            type: 'fubon',
            guestNumber: 1,
            bannerImg: isMobile.value ? getImgUrl('fubon-Banner-m') : getImgUrl('fubon-Banner'),
            mobileClass: 'home-mobile-search event',
            bgImg: getImgUrl('fubon-bg-1'),
            title: 'tourbobo 熱門人氣住宿',
            titleClass: 'fubon-hito-title'
          };
      }
    };

    var eventType = computed(function () {
      var _getEventType;

      return (_getEventType = getEventType()) === null || _getEventType === void 0 ? void 0 : _getEventType.type;
    });
    var banner = computed(function () {
      var _getEventType2;

      return (_getEventType2 = getEventType()) === null || _getEventType2 === void 0 ? void 0 : _getEventType2.bannerImg;
    });
    var bgImg = computed(function () {
      var _getEventType3;

      return (_getEventType3 = getEventType()) === null || _getEventType3 === void 0 ? void 0 : _getEventType3.bgImg;
    });
    var mobileClass = computed(function () {
      var _getEventType4;

      return (_getEventType4 = getEventType()) === null || _getEventType4 === void 0 ? void 0 : _getEventType4.mobileClass;
    });
    var title = computed(function () {
      var _getEventType5;

      return (_getEventType5 = getEventType()) === null || _getEventType5 === void 0 ? void 0 : _getEventType5.title;
    });
    var titleClass = computed(function () {
      var _getEventType6;

      return (_getEventType6 = getEventType()) === null || _getEventType6 === void 0 ? void 0 : _getEventType6.titleClass;
    });
    onMounted(function () {
      var _getEventType7;

      store.dispatch('form/setSearchCondition', {
        field: 'business_type',
        value: 1
      });
      store.dispatch('options/setGuestNumber', (_getEventType7 = getEventType()) === null || _getEventType7 === void 0 ? void 0 : _getEventType7.guestNumber);
    });
    return {
      eventType: eventType,
      banner: banner,
      bgImg: bgImg,
      isMobile: isMobile,
      mobileClass: mobileClass,
      title: title,
      titleClass: titleClass
    };
  }
});