import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/popover/style";
import _Popover from "vant/es/popover";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import { computed, defineComponent, inject, ref } from 'vue';
import { useSearchBar } from '@/hook/useSearchBar';
import SvgIcon from '@/components/share/SvgIcon.vue';
import KeywordSearch from '@/components/share/KeywordSearch.vue';
import PeoplePopover from '@/components/share/PeoplePopover.vue';
import format from 'date-fns/esm/format/index';
import { DATEWEEK_FORMAT } from '@/constants';
import { zhTW } from 'date-fns/locale';
import { isPast, isToday, addMonths, startOfToday, isAfter } from 'date-fns';
export default defineComponent({
  name: 'PcSearch',
  components: _defineProperty({
    SvgIcon: SvgIcon,
    KeywordSearch: KeywordSearch,
    PeoplePopover: PeoplePopover
  }, _Popover.name, _Popover),
  setup: function setup() {
    var t = inject('t');

    var _useSearchBar = useSearchBar(),
        searchValue = _useSearchBar.searchValue,
        setShowFilter = _useSearchBar.setShowFilter,
        search = _useSearchBar.search,
        locationInput = _useSearchBar.locationInput,
        dateRange = _useSearchBar.dateRange,
        durationDays = _useSearchBar.durationDays,
        showFilter = _useSearchBar.showFilter,
        businessType = _useSearchBar.businessType,
        handleSearch = _useSearchBar.handleSearch,
        setLocation = _useSearchBar.setLocation,
        onClickOverlay = _useSearchBar.onClickOverlay,
        resetSearch = _useSearchBar.resetSearch,
        hotelType = _useSearchBar.hotelType;

    var onDisabledDate = function onDisabledDate(date) {
      if (isToday(date)) {
        return false;
      } else {
        var lastDay = addMonths(startOfToday(), 11);
        return isPast(date) || isAfter(date, lastDay);
      }
    };

    var dateText = computed(function () {
      return [format(search.value.startDate, DATEWEEK_FORMAT, {
        locale: zhTW
      }), format(search.value.endDate, DATEWEEK_FORMAT, {
        locale: zhTW
      })];
    });
    var showHint = ref(false);

    var onVisibleChanged = function onVisibleChanged(visible) {
      showHint.value = visible;
    };

    return {
      t: t,
      locationInput: locationInput,
      searchValue: searchValue,
      setLocation: setLocation,
      onClickOverlay: onClickOverlay,
      resetSearch: resetSearch,
      dateRange: dateRange,
      showFilter: showFilter,
      setShowFilter: setShowFilter,
      search: search,
      durationDays: durationDays,
      handleSearch: handleSearch,
      dateText: dateText,
      businessType: businessType,
      hotelType: hotelType,
      onVisibleChanged: onVisibleChanged,
      showHint: showHint,
      onDisabledDate: onDisabledDate
    };
  }
});